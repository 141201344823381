document.addEventListener('click',function(e){
    if(e.target && (e.target.id === 'alertbox-close')){
        hideAlertbox(e.target.parentElement);
    }
});

function hideAlertbox(element) {
    element.classList.add('hide');
}

